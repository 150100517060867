import { useTranslation } from "react-i18next";

function GameType({ dataEl, elIndex, setSelectedGame, setTargetPoints }) {
  const { t } = useTranslation();

  return (
    // class game_selected
    <div className={`game_players_select ${dataEl.gameSelected && "game_selected"}`}>
      <div className="top_component">
        <div className="type_game_component">
          <img src="/imgs/emoji_cool.png" alt="" width="32" height="32" />
          <span>{dataEl.numbPlayers}</span>
        </div>
        <div className="type_game_component">
          <img src="/imgs/CARDS.png" alt="" width="42" height="42" />
          <span>{dataEl.numbCardsPerPlayer}</span>
        </div>
      </div>
      <button
        onClick={() => {
          setTargetPoints(0);
          setSelectedGame((prevState) =>
            prevState.map((el, index) => ({
              ...el,
              gameSelected: index === elIndex,
            }))
          );
        }}
        type="button"
        className={`action_btn_type2 btn_active ${dataEl.gameSelected && "game_selected"}`}
      >
        {dataEl.gameSelected ? t("btnSelectMsg") : t("btnSelectedMsg")}
      </button>
    </div>
  );
}

export default GameType;
