import { useEffect, useState } from "react";
import WelcomePage from "./pages/newPages/welcomePage";
import GamePage from "./pages/newPages/gamePage";
import PlayerRoaster from "./components/newComponents/PlayerRoaster";
import TableComponent from "./components/newComponents/TableComponent";
import HistoryComponent from "./components/newComponents/HistoryComponent";
import NavigationComponent from "./components/newComponents/NavigationComponent";
import LoaderComponent from "./components/newComponents/LoaderComponent";
import React, { Suspense } from "react";

function App({ socket }) {
  const [playerData, setPlayerData] = useState({});
  const [enemiesData, setEnemiesData] = useState([]);
  const [gameIsLoading, setGameIsLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [isMyTurn, setIsMyTurn] = useState(false);
  const [playersInfos, setPlayersInfo] = useState([]);
  const [gameover, setGameover] = useState(false);
  const [showEndRoundDetailScreen, setShowEndRoundDetailScreen] = useState(false);
  const [newEmoji, setNewEmoji] = useState(false);

  socket.on("connect", () => {
    if (socket.id !== playerData.playerID) handleDisconnect();
  });

  function handleDisconnect(msg) {
    setGameIsLoading(false);
    setGameStarted(false);
    setPlayerData({});
    setEnemiesData([]);
    socket.emit("endgamedisconnect");
    setGameover(false);
  }

  useEffect(() => {
    if (playerData.playerID) {
      socket.emit("get_all_players_data", playerData);
      const newPlayerInfo = {
        playerName: playerData.playerName,
        playerID: playerData.playerID,
      };

      setPlayersInfo((prevPlayersInfos) => {
        const playerExists = prevPlayersInfos.some((playerInfo) => playerInfo.playerID === newPlayerInfo.playerID);

        if (!playerExists) {
          return [...prevPlayersInfos, newPlayerInfo];
        }
        return prevPlayersInfos;
      });
    }

    function saveStartingGameData(myCards, startingPlayer, roomInfos) {
      // save my playing data
      setPlayerData((prevState) => {
        const updatedData = { ...prevState, playerCards: myCards, playerPlaying: startingPlayer, roomInfo: roomInfos };
        return updatedData;
      });
    }

    function saveEnemiesStartingGameData(enemyPlayerData) {
      const newPlayerInfo = {
        playerName: enemyPlayerData.playerName,
        playerID: enemyPlayerData.playerID,
      };

      setPlayersInfo((prevPlayersInfos) => {
        const playerExists = prevPlayersInfos.some((playerInfo) => playerInfo.playerID === newPlayerInfo.playerID);

        if (!playerExists) {
          return [...prevPlayersInfos, newPlayerInfo];
        }
        return prevPlayersInfos;
      });

      setEnemiesData((prevState) => {
        const playerExists = prevState.some((enemy) => enemy.playerID === enemyPlayerData.playerID);

        if (!playerExists) {
          return [...prevState, enemyPlayerData];
        }

        return prevState;
      });
    }

    function handleEndTurnData(enemyPlayerData) {
      // align the data with my player data
      setPlayerData((prevState) => {
        let copyState = { ...prevState };
        copyState.gamefieldCards = enemyPlayerData.gamefieldCards;
        copyState.playerPlaying = enemyPlayerData.playerPlaying;
        copyState.roundWinningSemen = enemyPlayerData.roundWinningSemen;
        return copyState;
      });
      // align the data with enemies data
      setEnemiesData((prevState) => {
        let copyState = [...prevState];
        copyState.forEach((player) => {
          player.playerPlaying = enemyPlayerData.playerPlaying;
        });
        return copyState;
      });
    }

    function handleEndRoundData(playerDataServer, winningRoundData, gameFinished) {
      setPlayerData((prevState) => {
        let copyState = { ...prevState };
        copyState.gamefieldCards = playerDataServer.gamefieldCards;
        copyState.roundWinningSemen = playerDataServer.roundWinningSemen;
        copyState.playerPlaying = playerDataServer.playerPlaying;
        copyState.winHistory.push(winningRoundData);
        copyState.lastRoundInfo = playerDataServer.lastRoundInfo;
        if (winningRoundData.cardOwner === prevState.playerID) {
          copyState.roundPoints = Number(copyState.roundPoints) + 1;
        }

        return copyState;
      });
      setEnemiesData((prevState) => {
        let copyState = [...prevState];
        copyState.forEach((player) => {
          player.playerPlaying = playerDataServer.playerPlaying;
          if (winningRoundData.cardOwner === player.playerID) {
            player.roundPoints = Number(player.roundPoints) + 1;
          }
        });
        return copyState;
      });

      if (gameFinished) {
        return endTheGame();
      }
      setShowEndRoundDetailScreen(true);
    }

    function endTheGame() {
      setGameover(true);
    }

    function handleEmoji(serverData) {
      setNewEmoji((prevState) => !prevState);
      if (serverData.playerID === playerData.playerID) {
        setPlayerData((prevState) => {
          return { ...prevState, playerEmoji: serverData.playerEmoji };
        });
      } else {
        setEnemiesData((prevState) => {
          return prevState.map((player) => {
            if (player.playerID === serverData.playerID) {
              return { ...player, playerEmoji: serverData.playerEmoji };
            } else {
              return { ...player };
            }
          });
        });
      }
    }

    socket.on("starting_game_data", saveStartingGameData);
    socket.on("starting_enemies_game_data", saveEnemiesStartingGameData);
    socket.on("endturn_gamedata", handleEndTurnData);
    socket.on("endround_gamedata", handleEndRoundData);
    socket.on("disconnect_users", handleDisconnect);
    socket.on("receive_emoji", handleEmoji);

    return () => {
      socket.off("game_can_start", saveStartingGameData);
      socket.off("starting_enemies_game_data", saveEnemiesStartingGameData);
      socket.off("endturn_gamedata", handleEndTurnData);
      socket.off("endround_gamedata", handleEndRoundData);
      socket.off("disconnect_users", handleDisconnect);
      socket.off("receive_emoji", handleEmoji);
    };
  }, [socket, playerData, enemiesData]);

  useEffect(() => {
    if (enemiesData.length >= playerData?.maxPlayersRoomSelected - 1) {
      setGameIsLoading(false);
      setGameStarted(true);
      if (playerData.playerID === playerData.playerPlaying) {
        setIsMyTurn(true);
      } else {
        setIsMyTurn(false);
      }
    }
  }, [enemiesData, playerData]);

  // new app code functions
  const [menuOpen, setMenuOpen] = useState(false);
  function handleOpenCloseMenu() {
    setMenuOpen((prevState) => !prevState);
  }

  return (
    <>
      {gameIsLoading ? (
        <LoaderComponent playerData={playerData} />
      ) : (
        <>
          {!gameStarted && (
            <Suspense fallback={<LoaderComponent />}>
              <WelcomePage socket={socket} setPlayerData={setPlayerData} setGameIsLoading={setGameIsLoading} />
            </Suspense>
          )}
          {gameStarted && (
            <>
              {playerData && enemiesData && (
                <>
                  <GamePage>
                    <NavigationComponent menuOpen={menuOpen} handleOpenCloseMenu={handleOpenCloseMenu} startNewGame={handleDisconnect} />
                    <HistoryComponent playerData={playerData} playersInfos={playersInfos} />
                    <TableComponent
                      socket={socket}
                      playerData={playerData}
                      setPlayerData={setPlayerData}
                      handleOpenCloseMenu={handleOpenCloseMenu}
                      isMyTurn={isMyTurn}
                      setIsMyTurn={setIsMyTurn}
                      gameover={gameover}
                      enemiesData={enemiesData}
                      startNewGame={handleDisconnect}
                      setShowEndRoundDetailScreen={setShowEndRoundDetailScreen}
                      showEndRoundDetailScreen={showEndRoundDetailScreen}
                      playersInfos={playersInfos}
                      newEmoji={newEmoji}
                    />
                    <PlayerRoaster playerData={playerData} enemiesData={enemiesData} />
                  </GamePage>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default App;
