function Card({ isMyTurn, playerData, card, setPlayerData, cardPlayed, setCardPlayed, setIsDraggingCard }) {
  function checkIfCardCanBePlayed() {
    const winSemen = playerData.roundWinningSemen;
    // if theres no semen/card played
    if (!winSemen) {
      return true;
    }

    // if the played card semen matches the winning semen
    if (card.cardSemen === winSemen) {
      return true;
    } else {
      // if player does not have any card with the winning semen can play whatever card he wants
      if (
        !playerData.playerCards.some((singleCard) => {
          return singleCard.cardSemen === winSemen;
        })
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  function handleDrag(e, card) {
    setIsDraggingCard(true);
    e.dataTransfer.setData("cardData", JSON.stringify(card));
  }

  function handleEndDrag() {
    setIsDraggingCard(false);
  }

  function handlePlayCard() {
    if (!isMyTurn) return;
    if (cardPlayed) return;
    if (!checkIfCardCanBePlayed()) return;
    setCardPlayed(true);
    setPlayerData((prevState) => {
      let copyState;
      if (prevState.gamefieldCards.length >= 1) {
        copyState = { ...prevState };
      } else {
        copyState = { ...prevState, roundWinningSemen: card.cardSemen };
      }
      copyState.playerCards = copyState.playerCards.filter((singleCard) => singleCard.cardName !== card.cardName);
      copyState.gamefieldCards = [...copyState.gamefieldCards, card];
      return copyState;
    });
  }

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <li
      className="card"
      onClick={handlePlayCard}
      draggable
      onTouchStart={(e) => {
        handleDrag(e, card);
      }}
      onTouchEnd={(e) => {
        handleEndDrag(e);
      }}
      onDragStart={(e) => {
        handleDrag(e, card);
      }}
      onDragEnd={(e) => {
        handleEndDrag(e);
      }}
    >
      <img
        style={{ userSelect: "none" }}
        draggable="false"
        src={`/imgs/${card.cardName}`}
        alt="poker card"
        width="84"
        height="84"
        onContextMenu={handleContextMenu}
      />
    </li>
  );
}

export default Card;
