import { useEffect } from "react";

function AlertComponent({ message, duration = 3000, showAlert, setShowAlert }) {
  useEffect(() => {
    // Set a timeout to hide the alert after the specified duration
    const timer = setTimeout(() => {
      setShowAlert(false); // Reset the showAlert state in the parent
    }, duration);

    // Clean up the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [duration, setShowAlert]);

  return (
    showAlert && (
      <div className="alert">
        <p>{message}</p>
        <div className="alert_actions">
          <img src="/imgs/alert.png" alt="" width="64" height="64" />
          <button
            className="action_btn_type2 btn_active"
            onClick={() => {
              setShowAlert(false);
            }}
          >
            Chiudi
          </button>
        </div>
      </div>
    )
  );
}

export default AlertComponent;
