function HistoryComponent({ playerData, playersInfos }) {
  function getPlayerName(cardOwnerId) {
    const player = playersInfos.find((enemy) => enemy.playerID === cardOwnerId);
    return player ? player.playerName : "Unknown Player";
  }

  return (
    <div className="history">
      <span>
        {playerData.playerName}
        <br /> Cronologia di gioco
      </span>
      <ul>
        {playerData.winHistory.length >= 1 &&
          playerData.winHistory.map((el, index) => {
            return (
              <li key={index} className="history_item">
                <p>{getPlayerName(el.cardOwner)} won</p>
                <img draggable="false" src={`/imgs/${el.cardName}`} alt="poker card" width="32" height="32" />
              </li>
            );
          })}
      </ul>
      <button className="action_btn_type2 btn_active">Chiudi cronologia</button>
    </div>
  );
}

export default HistoryComponent;
