import { Link } from "react-router-dom";

function RulesPage() {
  return (
    <div className="rules_page">
      <h2>RULES</h2>
      <p>
        Before starting the game, every player has to make a prediction about how many rounds they will win in one game.
        <br />
        The number of points that you can make per game will vary based on the type of game and number of players.
        <br />
        The maximum number of points that a player can achieve in one game is the same as the number of cards each player has.
        <br />A player can also take the decision to try to make no points for the game. In that case the player has to make sure to not win any round
        during the game and end up with zero points in order to win.
      </p>
      <p>There can be either multiple winners for one game either none.</p>
      <p>
        Every player starts with the same amount of cards.
        <br />
        In order every player during its own turn can play one card.
      </p>
      <p>
        First player to play in the round will decide the suit of the game which can be: diamonds, hearts, spades, clubs
        <br />
        Every player that follows has to play the same suit card. If the player does not have the suit he/she can play whichever card wants.
      </p>

      <p>
        The round ends when every player in the game plays one card and the winner of the round will be the player that played the highest card.
        <br />
        ***The highest card is the ACE.
      </p>

      <p>
        Spades are the special suit type of the game which means that if played these cards are considered the highest no matter other suit. Spades
        cards will always win on other suit cards.
      </p>
      <Link to="/" as="link" className="action_btn_type2 btn_active">
        Go Back to Game
      </Link>
    </div>
  );
}

export default RulesPage;
