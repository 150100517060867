function EndRoundDetails({ playerData, setShowEndRoundDetailScreen, playersInfos }) {
  function getPlayerName(cardOwnerId) {
    const player = playersInfos.find((enemy) => enemy.playerID === cardOwnerId);
    return player ? player.playerName : "Unknown Player";
  }

  return (
    <div className="endround_details">
      <div className="details_table">
        <span className="table_decor">
          <img src="/imgs/BACK.png" alt="" width="124" height="124" />
          <p>Spades Always Wins</p>
        </span>
        <div className="history_short_roundinfo">
          <p>Round</p>
          <span>{playerData.winHistory.length}</span>
        </div>
        <ul className="table_cards">
          {playerData?.lastRoundInfo?.map((singleCard) => {
            return (
              <li key={singleCard.cardName} className="table_cards_card">
                <img src={`/imgs/${singleCard.cardName}`} alt="poker game card" width="64" height="64" />
              </li>
            );
          })}
        </ul>
        <p
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "2rem",
            marginBottom: "1.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {getPlayerName(playerData.winHistory[playerData.winHistory.length - 1].cardOwner)} won
          <span>
            <img
              draggable="false"
              src={`/imgs/${playerData.winHistory[playerData.winHistory.length - 1].cardName}`}
              alt="poker card"
              width="32"
              height="32"
            />
          </span>
        </p>
        {/* <img className="cardsemen" draggable="false" src={`/imgs/${winningSemen}`} alt="poker card semen" width="64" height="64" /> */}

        <button
          className="action_btn_type2 btn_active"
          onClick={() => {
            setShowEndRoundDetailScreen(false);
          }}
        >
          Continua
        </button>
      </div>
    </div>
  );
}

export default EndRoundDetails;
