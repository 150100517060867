import Emoji from "./Emoji";

function PlayerRoaster({ playerData, enemiesData }) {
  return (
    <aside>
      <ul className="playerroaster">
        {playerData && (
          <li className={`player ${playerData.playerID === playerData.playerPlaying ? "active_player" : ""}`}>
            <div className="player_left_content">
              <div className="player_status player_emojipc">
                <Emoji player={playerData} type={"pc"} />
              </div>
              <div className="player_status">
                {playerData.gamefieldCards.filter((singleCard) => singleCard.cardOwner === playerData.playerID)[0]?.cardName ? (
                  <img
                    draggable="false"
                    src={`/imgs/${playerData.gamefieldCards.filter((singleCard) => singleCard.cardOwner === playerData.playerID)[0]?.cardName}`}
                    alt="poker card"
                    width="32"
                    height="32"
                  />
                ) : (
                  <img draggable="false" src="/imgs/BACK.png" alt="back of a poker card" width="32" height="32" />
                )}
              </div>
            </div>
            <div className="playerinfo playerinfo_pc">
              <div className="playerinfo_avatar"></div>
              <div className="playerinfo_info">
                <p className="text_custom"> {playerData.playerID === playerData.playerPlaying ? "Sta giocando..." : "In attesa..."}</p>
                <p>{playerData.playerName}</p>
                <div className="playerinfo_details">
                  <div className="detail_cards">
                    <img draggable="false" src="/imgs/CARDS.png" alt="bunch of poker cards" width="42" height="42" />
                    <p>Obiettivo:</p>
                    <span>{playerData.targetPoints}</span>
                  </div>
                  <div className="detail_cards">
                    <img draggable="false" src="./imgs/CARDS.png" alt="bunch of poker cards" width="42" height="42" />
                    <p>Attuale:</p>
                    <span>{playerData.roundPoints}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
        <li className="list_divider"></li>
        {enemiesData.map((player, index) => {
          return (
            <li key={index} className={`player ${player.playerID === playerData.playerPlaying ? "active_player" : ""}`}>
              <div className="player_left_content">
                <div className="player_status player_emojipc">
                  <Emoji player={player} type={"pc"} />
                </div>
                <div className="player_status">
                  {playerData.gamefieldCards.filter((singleCard) => singleCard.cardOwner === player.playerID)[0] ? (
                    <img
                      draggable="false"
                      src={`/imgs/${playerData.gamefieldCards.filter((singleCard) => singleCard.cardOwner === player.playerID)[0]?.cardName}`}
                      alt="poker card"
                      width="32"
                      height="32"
                    />
                  ) : (
                    <img draggable="false" src="/imgs/BACK.png" alt="back of a poker card" width="32" height="32" />
                  )}
                </div>
              </div>
              <div className="playerinfo playerinfo_pc">
                <div className="playerinfo_avatar"></div>
                <div className="playerinfo_info">
                  <p className="text_custom">{player.playerID === playerData.playerPlaying ? "Sta giocando..." : "In attesa..."}</p>
                  <p>{player.playerName}</p>
                  <div className="playerinfo_details">
                    <div className="detail_cards">
                      <img draggable="false" src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                      <p>Obiettivo:</p>
                      <span>{player.targetPoints}</span>
                    </div>
                    <div className="detail_cards">
                      <img draggable="false" src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                      <p>Attuale:</p>
                      <span>{player.roundPoints}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
}

export default PlayerRoaster;
