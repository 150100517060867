import { useTranslation } from "react-i18next";

function NavigationComponent({ menuOpen, handleOpenCloseMenu, startNewGame }) {
  const { t } = useTranslation();

  return (
    <nav className={`navmenu ${menuOpen ? "navmenu_open" : ""}`}>
      <div className="navmenu_actions">
        <div className="navmenu_msg">
          <p>{t("thankYou")}</p>
          <p>{t("welcomeMsg")}</p>
          <a href="/">{t("supportMeLinkMsg")}</a>
        </div>
        <div className="navmenu_actions_btns">
          <button
            className="action_btn_type2 btn_active"
            onClick={() => {
              handleOpenCloseMenu();
              startNewGame();
            }}
          >
            {t("navAbandon")}
          </button>
          <button
            className="action_btn_type2 btn_active"
            onClick={() => {
              handleOpenCloseMenu();
            }}
          >
            {t("navCancel")}
          </button>
        </div>
        <span className="table_decor">
          <img src="/imgs/BACK.png" alt="back design of a poker card" width="124" height="124" />
          <p>Spades Always Wins</p>
        </span>
      </div>
    </nav>
  );
}

export default NavigationComponent;
