const emojiIcons = [
  {
    emojiName: "emoji_angry.png",
  },
  {
    emojiName: "emoji_confused.png",
  },
  {
    emojiName: "emoji_cool.png",
  },
  {
    emojiName: "emoji_cry.png",
  },
  {
    emojiName: "emoji_dead.png",
  },
  {
    emojiName: "emoji_dizzy.png",
  },
  {
    emojiName: "emoji_dizzy.png",
  },
  {
    emojiName: "emoji_lol.png",
  },
  {
    emojiName: "emoji_sleeping.png",
  },
  {
    emojiName: "emoji_stareyes.png",
  },
  {
    emojiName: "emoji_vomiting.png",
  },
];

function ReactionsComponent({ handleCloseReactionsTab, socket, playerData }) {
  function sendEmoji(emojiName) {
    socket.emit("send_emoji", emojiName, playerData);
    handleCloseReactionsTab(false);
  }

  return (
    <div className="reactions_tab">
      <div className="reactions_list">
        <ul>
          {emojiIcons.map((emj) => {
            return (
              <li
                onClick={() => {
                  sendEmoji(emj.emojiName);
                }}
              >
                <img draggable="false" src={`imgs/${emj.emojiName}`} alt="emoji icon" width="54" height="54" />
              </li>
            );
          })}
        </ul>
        <button
          className="action_btn_type2 btn_active"
          onClick={() => {
            handleCloseReactionsTab(false);
          }}
        >
          Close Menu
        </button>
      </div>
    </div>
  );
}

export default ReactionsComponent;
