function LoaderComponent({ playerData }) {
  return (
    <div className="loader">
      <h3>In attesa di giocatori...</h3>
      <p>
        {playerData.roomInfo.roomConnectedPlayers} / {playerData.roomInfo.roomMaxPlayers}
      </p>
      <div className="loader_cards">
        <img loading="eager" draggable="false" src="/imgs/JOKER.png" alt="poker card red joker symbol" width="84" height="84" />
        <img loading="eager" draggable="false" src="/imgs/JOKER.png" alt="poker card red joker symbol" width="84" height="84" />
        <img loading="eager" draggable="false" src="/imgs/JOKER.png" alt="poker card red joker symbol" width="84" height="84" />
      </div>
    </div>
  );
}

export default LoaderComponent;
