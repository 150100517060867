import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import io from "socket.io-client";
import Rules from "./pages/newPages/Rules";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import "./utils/i18n";

const socket = io.connect("https://ideasplaza.online", {
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 6000,
  timeout: 20000,
  autoConnect: true,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App socket={socket} />,
    errorElement: (
      <div className="error_page">
        <h3>404 Page Not Found</h3>
        <Link to="/">Take me back to the Homepage</Link>
      </div>
    ),
  },
  {
    path: "/rules",
    element: <Rules />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
