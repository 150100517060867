import { useState, useEffect } from "react";

function Emoji({ player, type, newEmoji }) {
  const [showEmoji, setShowEmoji] = useState(true);

  useEffect(() => {
    setShowEmoji(true);

    const timer = setTimeout(() => {
      player.playerEmoji = null;
      setShowEmoji(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [player.playerEmoji, newEmoji, player]);

  return (
    <>
      {showEmoji && player.playerEmoji && type === "mobile" ? (
        <span className="mobile_emoji">
          <img src={`imgs/${player.playerEmoji}`} alt="emoji icon" width="48" height="48" />
        </span>
      ) : (
        showEmoji &&
        player.playerEmoji &&
        type === "pc" && (
          <img
            className="emojipc"
            src={`imgs/${player.playerEmoji}`}
            alt="emoji icon"
            width="32"
            height="32"
            style={{ position: "absolute", zIndex: "1" }}
          />
        )
      )}
    </>
  );
}

export default Emoji;
