import { useEffect, useState } from "react";
import Card from "./Card.js";
import GameOverComponent from "./GameOverComponent.js";
import AlertComponent from "./AlertComponent.js";
import EndRoundDetails from "./EndRoundDetails.js";
import ReactionsComponent from "./ReactionsComponent.js";
import Emoji from "./Emoji.js";
import { useTranslation } from "react-i18next";

function TableComponent({
  socket,
  playerData,
  setPlayerData,
  handleOpenCloseMenu,
  isMyTurn,
  setIsMyTurn,
  gameover,
  enemiesData,
  startNewGame,
  setShowEndRoundDetailScreen,
  showEndRoundDetailScreen,
  playersInfos,
  newEmoji,
}) {
  const [cardPlayed, setCardPlayed] = useState(false);
  const [winningSemen, setWinningSemen] = useState(null);
  const [currentlyPlayingPlayer, setCurrentlyPlayingPlayer] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showMobilePlayerInfo, setShowMobilePlayerInfo] = useState(false);
  const [showReactionsTab, setShowReactionsTab] = useState(false);
  const [mobileClickedPlayerData, setMobileClickedPlayerData] = useState({});
  const [isDraggingCard, setIsDraggingCard] = useState(false);
  const { t } = useTranslation();

  function handleGamefieldCard() {
    if (!isMyTurn) return;
    if (!cardPlayed) return;
    setCardPlayed(false);
    setPlayerData((prevState) => {
      let copyState;

      if (prevState.gamefieldCards.length === 1) {
        copyState = { ...prevState, roundWinningSemen: null };
      } else {
        copyState = { ...prevState };
      }
      let lastPlayedCard = copyState.gamefieldCards.pop();
      copyState.playerCards = [...copyState.playerCards, lastPlayedCard];
      return copyState;
    });
  }

  function renderWinningSemen(cardSemen) {
    switch (cardSemen) {
      case "clubs":
        return "CSymbol.png";
      case "diamonds":
        return "DSymbol.png";
      case "spades":
        return "SSymbol.png";
      case "hearts":
        return "HSymbol.png";
      default:
        return null;
    }
  }

  function endTurn() {
    if (!cardPlayed || !isMyTurn) return;
    setIsMyTurn(false);
    setCardPlayed(false);
    socket.emit("end_turn", playerData);
  }

  function handleShowAlert() {
    setShowAlert(false);
    setTimeout(() => setShowAlert(true), 0);
  }

  function showPlayerMobileData(bool, mobilePlayerData) {
    setShowMobilePlayerInfo(bool);
    setMobileClickedPlayerData(mobilePlayerData);
  }

  function handleCloseReactionsTab(bool) {
    setShowReactionsTab(bool);
  }

  useEffect(() => {
    if (!playerData) return;
    const convertedSemen = renderWinningSemen(playerData.roundWinningSemen);
    setWinningSemen(convertedSemen);
  }, [playerData]);

  useEffect(() => {
    if (!playerData || !enemiesData) return;

    setCurrentlyPlayingPlayer(() => {
      if (playerData.playerID === playerData.playerPlaying) {
        return playerData;
      }
      const activeEnemyPlayer = enemiesData.find((enemy) => enemy.playerID === playerData.playerPlaying);

      return activeEnemyPlayer || null;
    });
  }, [playerData, enemiesData]);

  useEffect(() => {
    if (!currentlyPlayingPlayer) return;
    if (currentlyPlayingPlayer.playerID === playerData.playerID) {
      handleShowAlert();
    }
  }, [currentlyPlayingPlayer?.playerID, playerData?.playerID]);

  function handleDragOver(e) {
    e.preventDefault();
  }
  function handleOnDrop(e) {
    const cardData = JSON.parse(e.dataTransfer.getData("cardData"));

    function checkIfCardCanBePlayed() {
      const winSemen = playerData.roundWinningSemen;
      // if theres no semen/card played
      if (!winSemen) {
        return true;
      }

      // if the played card semen matches the winning semen
      if (cardData.cardSemen === winSemen) {
        return true;
      } else {
        // if player does not have any card with the winning semen can play whatever card he wants
        if (
          !playerData.playerCards.some((singleCard) => {
            return singleCard.cardSemen === winSemen;
          })
        ) {
          return true;
        } else {
          return false;
        }
      }
    }

    function handlePlayCard() {
      if (!isMyTurn) return;
      if (cardPlayed) return;
      if (!checkIfCardCanBePlayed()) return;
      setCardPlayed(true);
      setPlayerData((prevState) => {
        let copyState;
        if (prevState.gamefieldCards.length >= 1) {
          copyState = { ...prevState };
        } else {
          copyState = { ...prevState, roundWinningSemen: cardData.cardSemen };
        }
        copyState.playerCards = copyState.playerCards.filter((singleCard) => singleCard.cardName !== cardData.cardName);
        copyState.gamefieldCards = [...copyState.gamefieldCards, cardData];
        return copyState;
      });
      setIsDraggingCard(false);
    }

    handlePlayCard();
  }

  return (
    <main>
      {showAlert && <AlertComponent message="E il tuo turno a giocare!" duration={3000} showAlert={showAlert} setShowAlert={setShowAlert} />}
      {gameover && <GameOverComponent playerData={playerData} enemiesData={enemiesData} startNewGame={startNewGame} />}
      {showEndRoundDetailScreen && (
        <EndRoundDetails playerData={playerData} setShowEndRoundDetailScreen={setShowEndRoundDetailScreen} playersInfos={playersInfos} />
      )}
      {showReactionsTab && (
        <ReactionsComponent handleCloseReactionsTab={handleCloseReactionsTab} socket={socket} playerData={playerData} setPlayerData={setPlayerData} />
      )}

      <div className="history_short">
        <div className="history_short_roundinfo">
          <p>{t("gameRound")}</p>
          <span>{playerData.winHistory.length}</span>
        </div>
        {!playerData.roundWinningSemen ? (
          <>
            <img className="cardsemen" draggable="false" src="/imgs/BACK.png" alt="poker card semen" width="64" height="64" />
            <p>{t("gameInfoWait")}</p>
          </>
        ) : (
          <>
            <img className="cardsemen" draggable="false" src={`/imgs/${winningSemen}`} alt="poker card semen" width="64" height="64" />
            <p>{t("gameInfoDecided")}</p>
          </>
        )}

        <button
          className="action_btn_type1"
          onClick={() => {
            handleOpenCloseMenu();
          }}
        >
          {t("gameMenu")}
        </button>
      </div>

      <div className="table">
        <div className={`playermobile_info ${showMobilePlayerInfo && "playermobile_info_show"}`}>
          <p>{mobileClickedPlayerData?.username}</p>
          <div className="playermobile_info_div">
            <div className="playerinfo_avatar avatar avatar_1" style={{ padding: "1rem" }}>
              <img src={`imgs/${mobileClickedPlayerData?.avatar}.png`} alt="avatar icon" width="54" height="54" />
            </div>
            <div className="playerinfo_details playerinfo_details_roaster">
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <p>{t("gameTarget")}</p>
                <span>{mobileClickedPlayerData?.target}</span>
              </div>
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <p>{t("gameCurrent")}</p>
                <span>{mobileClickedPlayerData?.current}</span>
              </div>
            </div>
          </div>
          <button
            className="action_btn_type2 btn_active"
            onClick={() => {
              showPlayerMobileData(false);
            }}
          >
            {t("navCancel")}
          </button>
        </div>
        <span className="table_decor">
          <img src="/imgs/BACK.png" alt="" width="124" height="124" />
          <p>Spades Always Wins</p>
        </span>
        <ul className={`table_cards ${isDraggingCard && "table_cards_dragzone"}`} onDragOver={handleDragOver} onDrop={handleOnDrop}>
          {playerData.gamefieldCards.map((singleCard) => {
            return (
              <li key={singleCard.cardName} className="table_cards_card" onClick={handleGamefieldCard}>
                <img src={`/imgs/${singleCard.cardName}`} alt="poker game card" width="84" height="84" />
              </li>
            );
          })}
        </ul>
        <div className="playerinfo playerinfo_pc">
          <div className="playerinfo_avatar avatar" style={{ padding: "1rem" }}>
            <img src={`imgs/${currentlyPlayingPlayer?.playerAvatar}.png`} alt="avatar icon" width="54" height="54" />
          </div>
          <div className="playerinfo_info">
            <div className="player_info_text">
              <p className="text_custom">{t("gamePlayerPlaying")}</p>
              <p>{currentlyPlayingPlayer?.playerName}</p>
            </div>
            <div className="playerinfo_details playerinfo_details_roaster">
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <p>{t("gameTarget")}</p>
                <span>{currentlyPlayingPlayer?.targetPoints}</span>
              </div>
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <p>{t("gameCurrent")}</p>
                <span>{currentlyPlayingPlayer?.roundPoints}</span>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="playerinfo playerinfo_mobile">
          <div className="playerinfo_avatar avatar">
            <img src={`imgs/${currentlyPlayingPlayer?.playerAvatar}.png`} alt="avatar icon" width="48" height="48" />
          </div>
          <div className="playerinfo_info">
            <div className="player_info_text">
              <p className="text_custom">{t("gamePlayerPlaying")}</p>
              <p>{currentlyPlayingPlayer?.playerName}</p>
            </div>
            <div className="playerinfo_details playerinfo_details_roaster">
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <span>{currentlyPlayingPlayer?.targetPoints}</span>
              </div>
              <div className="detail_cards">
                <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                <span>{currentlyPlayingPlayer?.roundPoints}</span>
              </div>
            </div>
          </div>
        </div>
        <ul className="playersroaster_mobile">
          {playerData && (
            <li
              className={`playersroaster_mobile_player avatar ${playerData.playerID === playerData.playerPlaying && "active_mobile"}`}
              onClick={() => {
                showPlayerMobileData(true, {
                  username: playerData.playerName,
                  target: playerData.targetPoints,
                  current: playerData.roundPoints,
                  avatar: playerData.playerAvatar,
                });
              }}
            >
              <Emoji player={playerData} type={"mobile"} newEmoji={newEmoji} />
              <img src={`imgs/${playerData.playerAvatar}.png`} alt="avatar icon" width="48" height="48" />
            </li>
          )}
          <li className="mobile_divider"></li>
          {enemiesData.map((player) => {
            return (
              <li
                key={player.playerName}
                className={`playersroaster_mobile_player avatar ${player.playerID === playerData.playerPlaying && "active_mobile"}`}
                onClick={() => {
                  showPlayerMobileData(true, {
                    username: player.playerName,
                    target: player.targetPoints,
                    current: player.roundPoints,
                    avatar: player.playerAvatar,
                  });
                }}
              >
                <Emoji player={player} type={"mobile"} newEmoji={newEmoji} />
                <img src={`imgs/${player.playerAvatar}.png`} alt="avatar icon" width="48" height="48" />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="game_actions">
        <div className="game_actions_main">
          {cardPlayed && isMyTurn ? (
            <>
              <button className="action_btn_type2 btn_active" onClick={handleGamefieldCard}>
                {t("gameCancelBtn")}
              </button>
              <button className="action_btn_type2 btn_active" onClick={endTurn}>
                {t("gamePlayBtn")}
              </button>
            </>
          ) : (
            <>
              <button className="action_btn_type2">{t("gameCancelBtn")}</button>
              <button className="action_btn_type2">{t("gamePlayBtn")}</button>
            </>
          )}
        </div>
        <button className="action_btn_type2 btn_active" onClick={() => handleCloseReactionsTab(true)}>
          {t("gameReactionBtn")}
        </button>
      </div>
      <div className="playercards">
        <ul className="playercards_ul">
          {playerData?.playerCards?.map((card, index) => {
            return (
              <Card
                isMyTurn={isMyTurn}
                playerData={playerData}
                card={card}
                key={index}
                setPlayerData={setPlayerData}
                cardPlayed={cardPlayed}
                setCardPlayed={setCardPlayed}
                setIsDraggingCard={setIsDraggingCard}
              />
            );
          })}
        </ul>
      </div>
    </main>
  );
}

export default TableComponent;
