import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "EN",
    fallbackLng: "EN",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      EN: {
        translation: {
          welcomeMsg: "If you want to support this game and the development of many more please consider:",
          supportMeLinkMsg: "Buy me a Coffee!",
          gameRules: "Game Rules",
          playerUsername: "Player Username",
          playerUsernamePlaceholder: "Input your player username",
          gameType: "Select game type",
          gameObjectScore: "Select target points",
          gameAvatar: "Avatars",
          gameSettings: "Private room settings",
          createPrivateRoom: "Create Private Room",
          privateRoomPlaceholder: "Input a name for your private room",
          joinRoomPlaceholder: "Input the name of the private room you want to join",
          joinPrivateRoom: "Join Private Room",
          startGame: "Start Game",
          btnSelectMsg: "Select",
          btnSelectedMsg: "Selected",
          joinPrivateRoomMsg1: "Create or join a private custom room: ",
          joinPrivateRoomMsg2: "*leave the field empty to play publicaly",
          //
          gameRound: "Round",
          gameInfoWait: "Waiting for the suit to be decided",
          gameInfoDecided: "Round winning suit",
          gameMenu: "Menu",
          gameHistory: "Game History",
          gamePlayerPlaying: "Playing...",
          gamePlayerWaiting: "Waiting...",
          gameTarget: "Target: ",
          gameCurrent: "Current: ",
          gamePlayBtn: "Play Card",
          gameCancelBtn: "Undo Card",
          gameReactionBtn: "Reactions",
          //
          navCancel: "Close Menu",
          navAbandon: "Abbandon Game",
          thankYou: "Thank you for playing!",
        },
      },
      IT: {
        translation: {
          welcomeMsg: "Se vuoi supportare questo gioco e lo sviluppo di tanti altri considera:",
          supportMeLinkMsg: "Offrimi un caffe!",
          gameRules: "Regolamento di Gioco",
          playerUsername: "Nome Giocatore",
          playerUsernamePlaceholder: "Inserisci un nome giocatore",
          gameType: "Seleziona tipo partita",
          gameObjectScore: "Seleziona Punteggio da raggiungere",
          gameAvatar: "Avatars",
          gameSettings: "Impostazioni per partite private",
          createPrivateRoom: "Crea Stanza Privata",
          privateRoomPlaceholder: "Inserisci un nome da assegnare alla tua stanza privata",
          joinRoomPlaceholder: "Inserisci il nome della stanza alla quale vuoi accedere",
          joinPrivateRoom: "Unisciti ad una Stanza Privata",
          startGame: "Inizia Partita",
          btnSelectMsg: "Seleziona",
          btnSelectedMsg: "Selezionato",
          joinPrivateRoomMsg1: "Crea o unisciti ad una stanza privata: ",
          joinPrivateRoomMsg2: "*lasciare il campo vuoto se si vuole giocare publicamente",
          //
          gameRound: "Round",
          gameInfoWait: "In attesa del seme vincente del round",
          gameInfoDecided: "Seme vincente del round",
          gameMenu: "Menu",
          gameHistory: "Cronologia di Gioco",
          gamePlayerPlaying: "Sta giocando...",
          gamePlayerWaiting: "In attesa...",
          gameTarget: "Obiettivo: ",
          gameCurrent: "Attuale: ",
          gamePlayBtn: "Gioca Carta",
          gameCancelBtn: "Annulla Carta",
          gameReactionBtn: "Reazione",
          //
          navCancel: "Chiudi",
          navAbandon: "Abbandona partita",
          thankYou: "Grazie per aver giocato!",
        },
      },
    },
  });

export default i18n;
