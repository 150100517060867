import AlertComponent from "../../components/newComponents/AlertComponent";
import GameType from "../../components/newComponents/GameType";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useTranslation, Trans } from "react-i18next";

const gameTypesInitialData = [
  {
    numbPlayers: 2,
    numbCardsPerPlayer: 10,
    gameSelected: true,
  },
  {
    numbPlayers: 3,
    numbCardsPerPlayer: 8,
    gameSelected: false,
  },
  {
    numbPlayers: 4,
    numbCardsPerPlayer: 9,
    gameSelected: false,
  },
  {
    numbPlayers: 5,
    numbCardsPerPlayer: 8,
    gameSelected: false,
  },
  {
    numbPlayers: 6,
    numbCardsPerPlayer: 8,
    gameSelected: false,
  },
];

const avatarsListInitial = [
  { avatarName: "avatar_1", selected: true },
  { avatarName: "avatar_2", selected: false },
  { avatarName: "avatar_3", selected: false },
  { avatarName: "avatar_4", selected: false },
  { avatarName: "avatar_5", selected: false },
  { avatarName: "avatar_6", selected: false },
  { avatarName: "avatar_7", selected: false },
  { avatarName: "avatar_8", selected: false },
];

function WelcomePage({ socket, setPlayerData, setGameIsLoading }) {
  const [selectedGame, setSelectedGame] = useState(gameTypesInitialData);
  const [username, setUsername] = useState("");
  const [targetPoints, setTargetPoints] = useState(0);
  const [selectedAvatar, setSelectedAvatar] = useState(avatarsListInitial);
  //
  const [createPrivateRoom, setCreatePrivateRoom] = useState(false);
  const [newPrivateRoomName, setNewPrivateRoomName] = useState("");
  //
  const [joinPrivateRoom, setJoinPrivateRoom] = useState(false);
  const [joinRoomName, setJoinRoomName] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([
    {
      lng: "EN",
      selected: true,
    },
    {
      lng: "IT",
      selected: false,
    },
    // {
    //   lng: "FR",
    //   selected: false,
    // },
    // {
    //   lng: "JP",
    //   selected: false,
    // },
  ]);
  //
  const [showAlert, setShowAlert] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSelectedLanguage((prevState) => {
      return prevState.map((lngEl) => {
        return {
          ...lngEl,
          selected: lngEl.lng === i18n.language,
        };
      });
    });
  }, [i18n.language]);

  function handleShowAlert() {
    setShowAlert(false);
    setTimeout(() => setShowAlert(true), 0);
  }

  function enterLobby(event) {
    event.preventDefault();
    handleShowAlert();
    if (
      !username ||
      targetPoints === null ||
      targetPoints === undefined ||
      !selectedAvatar.some((el) => el.selected === true) ||
      !selectedGame.some((el) => el.gameSelected === true)
    )
      return handleShowAlert();
    let numberPlayersRoom = selectedGame.filter((el) => el.gameSelected === true)[0].numbPlayers;
    const playerSelectedAvatar = selectedAvatar.filter((el) => el.selected === true)[0].avatarName;
    socket.emit(
      "enter_lobby",
      username,
      targetPoints,
      playerSelectedAvatar,
      Number(numberPlayersRoom),
      newPrivateRoomName,
      joinRoomName,
      ({ status, additionalData, message, maxPlayerReached }) => {
        if (status === "success") {
          setPlayerData(additionalData);
          return setGameIsLoading(true);
        } else {
          alert("Error", message);
        }
      }
    );
  }

  function hanleLanguageChange(clickedIndex) {
    const updatedLanguage = selectedLanguage.map((el, index) => {
      return {
        ...el,
        selected: index === clickedIndex,
      };
    });
    setSelectedLanguage(updatedLanguage);
  }

  function handleAvatarClick(clickedIndex) {
    const updatedAvatars = selectedAvatar.map((el, index) => {
      return {
        ...el,
        selected: index === clickedIndex,
      };
    });
    setSelectedAvatar(updatedAvatars);
  }

  function changeLanguage(lng, index) {
    i18n.changeLanguage(lng);
    hanleLanguageChange(index);
  }

  return (
    <div className="welcome_page">
      {showAlert && (
        <AlertComponent
          message="Inserire tutti i dati e selezionare tipo partita"
          duration={3000}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}

      <form className="welcome_form" onSubmit={enterLobby}>
        <div className="welcome_section">
          <span className="table_decor">
            <img src="/imgs/BACK.png" alt="game logo" width="124" height="124" />
            <p>Spades Always Wins</p>
          </span>

          <div className="welcome_section_actions">
            <p>{t("gameRound")}</p>
            <a href="https://buymeacoffee.com/laurentiutorn" rel="noreferrer" target="_blank">
              ☕ {t("supportMeLinkMsg")}
            </a>
            <div style={{ display: "flex", gap: "0.5rem", marginTop: "1.5rem" }}>
              {selectedLanguage.map((lng, index) => {
                return (
                  <button
                    key={lng.lng}
                    type="button"
                    className={`action_btn_type2 ${lng.selected && "btn_active"}`}
                    onClick={() => changeLanguage(lng.lng, index)}
                  >
                    {lng.lng}
                  </button>
                );
              })}
            </div>
            <Link to="/rules" as="button" type="button" className="action_btn_type2 btn_active">
              {t("gameRules")}
            </Link>
          </div>
        </div>
        <div>
          <label htmlFor="username">{t("playerUsername")}:</label>
          <input
            className="inputtext"
            id="username"
            name="username"
            type="text"
            placeholder={t("playerUsernamePlaceholder")}
            autoComplete="username"
            maxLength="20"
            required
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <div className="game_players_container">
          <span className="game_players_container_label">{t("gameType")}:</span>
          {selectedGame.map((el, elIndex) => {
            return <GameType key={elIndex} dataEl={el} elIndex={elIndex} setSelectedGame={setSelectedGame} setTargetPoints={setTargetPoints} />;
          })}
        </div>
        <label htmlFor="rangeInput">{t("gameObjectScore")}:</label>
        <div className="game_target_points">
          <input
            type="range"
            min="0"
            max={
              selectedGame.filter((el) => {
                return el.gameSelected === true;
              })[0].numbCardsPerPlayer
            }
            step="1"
            id="rangeInput"
            name="rangeInput"
            onChange={(e) => {
              setTargetPoints(e.target.value);
            }}
          />
          <span id="rangeValue">{targetPoints}</span>
        </div>
        <span className="game_players_container_label">Avatars:</span>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1.5rem", justifyContent: "center", marginTop: "1.5rem" }}>
          {selectedAvatar.map((el, index) => {
            return (
              <div
                key={el.avatarName}
                className={`playerinfo_avatar avatar ${el.selected && "selected_avatar"}`}
                style={{ padding: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}
                onClick={() => {
                  handleAvatarClick(index);
                }}
              >
                <img src={`imgs/avatar_${index + 1}.png`} alt="avatar icon" width="54" height="54" />
              </div>
            );
          })}
        </div>
        <span className="game_players_container_label">{t("gameSettings")}:</span>
        <div className="game_actions_main">
          <button
            type="button"
            className={`action_btn_type2 ${createPrivateRoom && "btn_active"}`}
            onClick={() => {
              setJoinPrivateRoom(false);
              setCreatePrivateRoom(true);
              setJoinRoomName("");
            }}
          >
            {t("createPrivateRoom")}
          </button>
          <button
            type="button"
            className={`action_btn_type2 ${joinPrivateRoom && "btn_active"}`}
            onClick={() => {
              setJoinPrivateRoom(true);
              setCreatePrivateRoom(false);
              setNewPrivateRoomName("");
            }}
          >
            {t("joinPrivateRoom")}
          </button>
        </div>
        {createPrivateRoom && (
          <div className="custom_settings">
            <label htmlFor="privateRoomName" style={{ textAlign: "left" }}>
              {t("joinPrivateRoomMsg1")}
              <span style={{ fontSize: "1.2rem" }}>{t("joinPrivateRoomMsg2")}</span>
            </label>
            <input
              className="inputtext"
              id="privateRoomName"
              name="privateRoomName"
              type="text"
              placeholder={t("privateRoomPlaceholder")}
              maxLength="20"
              minLength="10"
              value={newPrivateRoomName}
              onChange={(e) => {
                setNewPrivateRoomName(e.target.value);
              }}
            />
          </div>
        )}
        {joinPrivateRoom && (
          <div className="custom_settings">
            <label htmlFor="joinRoomName" style={{ textAlign: "left" }}>
              {t("joinPrivateRoomMsg1")}
              <span style={{ fontSize: "1.2rem" }}>{t("joinPrivateRoomMsg2")}</span>
            </label>
            <input
              className="inputtext"
              id="joinRoomName"
              name="joinRoomName"
              type="text"
              placeholder={t("joinRoomPlaceholder")}
              maxLength="20"
              minLength="10"
              value={joinRoomName}
              onChange={(e) => {
                setJoinRoomName(e.target.value);
              }}
            />
          </div>
        )}
        <button className="action_btn_type2 btn_active btn_last" type="submit">
          {t("startGame")}
        </button>
      </form>
    </div>
  );
}

export default WelcomePage;
