function GameOverComponent({ playerData, enemiesData, startNewGame }) {
  return (
    <div className="gameover">
      <div className="gameover_msg">
        <p>Thank you for playing!</p>
        <p>
          If you enjoiyed the game please consider supporting me releasing new games by buying me a coffee and keeping me awake during the long hours
          of coding!
        </p>
        <a href="#">LINK TO BUYMEACOFFEE</a>
        <h2>Game Finished!</h2>
        <button
          className="action_btn_type2 btn_active"
          onClick={() => {
            startNewGame();
          }}
        >
          Play new game!
        </button>
      </div>
      <ul className="winners_roaster">
        {playerData && (
          <li className="winner_player">
            <p className="winner_p">{playerData.playerName}</p>
            <div className="winner_player_stats">
              <div className="player_status">
                <img src="./imgs/emoji_lol.png" alt="emoji icon" width="32" height="32" />
              </div>
              <div className="playerinfo_details">
                <div className="detail_cards">
                  <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                  <p>Target:</p>
                  <span>{playerData.targetPoints}</span>
                </div>
                <div className="detail_cards">
                  <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                  <p>Current:</p>
                  <span>{playerData.roundPoints}</span>
                </div>
              </div>
            </div>
            <p className="winner_msg">You missed this time but theres always next time!</p>
          </li>
        )}
        {enemiesData.map((singlePlayer) => {
          return (
            <li key={singlePlayer.playerName} className="winner_player">
              <p className="winner_p">{singlePlayer.playerName}</p>
              <div className="winner_player_stats">
                <div className="player_status">
                  <img src="./imgs/emoji_lol.png" alt="emoji icon" width="32" height="32" />
                </div>
                <div className="playerinfo_details">
                  <div className="detail_cards">
                    <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                    <p>Target:</p>
                    <span>{singlePlayer.targetPoints}</span>
                  </div>
                  <div className="detail_cards">
                    <img src="imgs/CARDS.png" alt="poker cards" width="42" height="42" />
                    <p>Current:</p>
                    <span>{singlePlayer.roundPoints}</span>
                  </div>
                </div>
              </div>
              <p className="winner_msg">You missed this time but theres always next time!</p>
            </li>
          );
        })}
      </ul>
      <span className="table_decor">
        <img src="/imgs/BACK.png" alt="back design of a poker card" width="124" height="124" />
        <p>Spades Always Wins</p>
      </span>
    </div>
  );
}

export default GameOverComponent;
